import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinearProgress from '@mui/material/LinearProgress';

export default function DataGridDemo() {
  const [codebook, setCodebook] = useState('C002');

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'code', headerName: 'code', width: 70},
    {
      field: 'active',
      headerName: 'active',
      width: 80,
      renderCell: (params) => (
        <>
        {params.row.active ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : ''}
        </>
      ),
    },
    { field: 'name', headerName: 'name', width: 300 },
    { field: 'description', headerName: 'description', width: 300 },
    ...(codebook === 'C002' ? [{ field: 'additionalFields', headerName: 'category, google category', width: 200 }] : []),

  ];

    const [open, setOpen] = useState(false);

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
  
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [item, setItem] = useState(Object);
    

    const handleCodebookChange = (event: SelectChangeEvent) => {
        console.log('codebook changed to ' + event.target.value)
        setCodebook(event.target.value);
    };

    // OPEN DIALOG
    const handleOpen = (props:any) => {
      if (codebook === 'C002' && props.additionalFields === undefined )
        props.additionalFields = ['',''];
        
      setItem(props);
      setOpen(true);
    };

    //CLOSE DIALOG
    const handleClose = () => {
      setOpen(false);
    };
    
    //SAVE DIALOG
    const handleSave = async() =>{

      console.log(item);
  
      const token = await getAccessTokenSilently();
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + token },
        body: JSON.stringify(item)
        };
      await fetch(process.env.REACT_APP_CODEBOOK_URL+"/"+codebook, requestOptions);

      if (item.id === undefined)
      {
          //adding new item to table
          item.id = item.code;
          const newTable = [...tableData, item as never];
          setTableData(newTable);
      }
      else
      {
          //updating item in table
          const updatedItems = tableData.map((i:any) =>
            i.code === item.code ? { ...i, name: item.name, description: item.description, active: item.active, additionalFields: item.additionalFields } : i
          );
          setTableData(updatedItems as any);
      }
      setOpen(false);
    }

    const handleCancel = async() => {
      setOpen(false);
    }

    // ADD ITEM
    const handleAdd = async() => {
      //calculate new code
      const prefix = (tableData[0] as any).code.substring(0,2);
      const maxNumber: number = Math.max(
        ...tableData.map((item:any) => parseInt(item.code.match(/\d+/)?.[0] || '0', 10))
      ) + 1;
      console.log('next available code '+ prefix+String(maxNumber).padStart(3, '0'))
      const newItem = { code:prefix+String(maxNumber).padStart(3, '0'),name:'',description:'', active: true} as never;
      handleOpen(newItem);
    }
    
    useEffect(() => {

      const loadCodebook = async() => {
          setTableData([]);
          
          const token = await getAccessTokenSilently();

          const response = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId="+codebook, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response.status) {
              const codebookJson = await response.json();
              if (codebookJson != null)
                setTableData(codebookJson.items);
              console.log("data loaded.")
            }
  
          if (codebook === 'C002') {
              console.log("adding columen")
              columns.push({ field: 'additionalFields', headerName:'', width: 200, renderCell: (params:any) => (<> (params.row.additionalFields?item.additionalFields[0]:'')</>) });
          }
      };


      loadCodebook();
      // eslint-disable-next-line
    }, [getAccessTokenSilently,codebook])
   
  return (
    <Box sx={{ height: `auto`, width: '100%', verticalAlign:'middle' }}>
        <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl sx={{ m: 1, minWidth: 320 }} size="small">
                <InputLabel id="demo-select-small-label">Codebook</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  autoWidth
                  label="Type"
                  value={codebook}
                  onChange={handleCodebookChange}
                >
                      <MenuItem value='C002' key='C002'>C002 - Purposes</MenuItem>
                      <MenuItem value='C003' key='C003'>C003 - Contract type</MenuItem>
                      <MenuItem value='C004' key='C004'>C004 - Cookie category</MenuItem>
                      <MenuItem value='C005' key='C005'>C005 - Company email type</MenuItem>
                      <MenuItem value='C006' key='C006'>C006 - Company url type</MenuItem>
                      <MenuItem value='C007' key='C007'>C007 - Web tags</MenuItem>
                </Select>
                </FormControl>
                <Button variant="contained" sx={{ marginLeft :10}} onClick={handleAdd}>Add</Button>
        </Box>
        <Box sx={{ width: '100%' }}>
                { tableData.length === 0 ? 
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
      />

<Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "1020px!important",
            heigh: "900px",
            maxHeight:"900px!important"
          },
        }}
      >
      <DialogTitle>Codebook item {item.code}</DialogTitle>

        <DialogContent dividers>


            <TextField
                    helperText="Name"
                    placeholder="Name"
                    value={item.name}
                    fullWidth 
                    onChange={(event:any) => setItem((prevState:any) => ({
                                                  ...prevState,
                                                    name: event.target.value,
                                                }))}
                />

            <TextField
                  helperText="Description"
                  placeholder="Description"
                  value={item.description}
                  fullWidth
                  onChange={(event:any) => setItem((prevState:any) => ({
                    ...prevState,
                      description: event.target.value,
                  }))}
              />

              <FormControlLabel label="Active"
                  labelPlacement="start"
                  control={<Checkbox 
                  checked={item.active}
                  onChange={(event:any) => { setItem((prevState:any) => ({
                    ...prevState,
                      active: event.target.checked,
                  }));console.log(item);console.log(event.target.checked);}}
                  />}  />

              {codebook === 'C002' && (
                  <TextField
                  helperText="Category"
                  placeholder="category"
                  value={item.additionalFields?item.additionalFields[0]:''}
                  fullWidth
                  onChange={(event:any) => setItem((prevState:any) => ({
                    ...prevState,
                      additionalFields: [event.target.value],
                  }))}
                  />      
              )}
                {codebook === 'C002' && (
                  <TextField
                  helperText="Google Consent Category"
                  placeholder="googleCategory"
                  value={item.additionalFields?(item.additionalFields[1]?item.additionalFields[1]:''):''}
                  fullWidth
                  onChange={(event:any) => setItem((prevState:any) => {
                    const newArray = [...prevState.additionalFields]; // Create a copy of the array
                    if (newArray.length < 2)
                        newArray.push(event.target.value)
                    else
                        newArray[1] = event.target.value;
                    return {...prevState, additionalFields: newArray};

                  })}
                  />
              )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleSave}>Save</Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
        </Dialog>
    </Box>
  );
}