import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="login.waulter.eu"
      clientId="D441PMI8Af2UqWqsoQBZb19HRZQ8HuNe"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://consentizer-api.azure-api.net/fn-consentizer-admin",
        scope: "openid profile email write:companies"
      }}>
        <App />
    </Auth0Provider>

  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
