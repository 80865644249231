import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { routes } from "./routes";
import MIcon from './components/MIcon';

const drawerWidth = 180;



interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export default function ResponsiveDrawer(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  };

  interface ListItemLinkProps {
    icon: string;
    primary: string;
    to: string;
  }


  function ListItemLink(props: ListItemLinkProps) {
    const { primary, to, icon } = props;
  
    return (
        <ListItem button component={Link} to={to} key={primary}>
          <ListItemIcon><MIcon name={icon}/></ListItemIcon>          
          <ListItemText primary={primary} />
        </ListItem>
    );
  }

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout 
  } = useAuth0();

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
      {routes.map((page, index) => (
            ((isAuthenticated && page.key !== 'login') || (!isAuthenticated && page.key === 'login')) ?
              <ListItemLink key={page.key} to={page.pathWithoutParams} primary={page.title} icon={page.icon} />
              : null
          
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Waulter Administration
          </Typography>
          
          {!isAuthenticated && (
            <Button
            id="qsLoginBtn"
            color="inherit"
            className="btn-margin"
            onClick={() => loginWithRedirect()}
          >
            Log in
          </Button>
          )}
          {isAuthenticated && (
          <Button color="inherit">{user?.email}</Button>
          )}
          {isAuthenticated && (
          <Button color="inherit" onClick={() => logout({ logoutParams: { returnTo:  process.env.REACT_APP_LOGOUT_URL } })}>Logout</Button>
          )}





        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
