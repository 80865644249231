import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useAuth0 } from "@auth0/auth0-react";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';

interface ItemSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (selectedItem: string) => void;
}

const ItemSelectionDialog: React.FC<ItemSelectionDialogProps> = ({ open, onClose, onSelect }) => {


    const columns: GridColDef[] = [
    {
        field: "actions",
        headerName: "Actions",
        width:50,
        renderCell: (params) => {
        return (
            <IconButton onClick={() => handleItemSelect(params.row.consentizerId)}>
            <RadioButtonUnchecked />
            </IconButton>
        );
        }
    },
    { field: 'consentizerId', headerName: 'Consentizer ID', width: 140},
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'email', headerName: 'Email', width: 300 }
    ];

  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [search, setSearch] = useState('');
  const [tableData, setTableData] = useState([]);



  const handleItemSelect = (selectedItem: string) => {
    onSelect(selectedItem);
    onClose();
  };

    //CLOSE DIALOG
    const handleClose = () => {
        onSelect('')
        onClose();
    };

    //PRESS ENTER IN SEARCH TEXT BOX
    const handleEnterInUrl = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
        e.preventDefault(); // prevent form submission
        loadData();
    }
    };

    //LOAD DATA WITH USERS
    const loadData = async() => {

        console.log("button clicked ");
        setLoading(true);
        const token = await getAccessTokenSilently();

        const response = await fetch(process.env.REACT_APP_USERS_URL +"?search="+search, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        });
        
        if (response.status) {
            const users = await response.json();
            if (users != null)
            setTableData(users);
            console.log("data loaded.")
        }
        setLoading(false);
    };


  return (
    <Dialog open={open} onClose={onClose}
        maxWidth="lg"
        PaperProps={{
            sx: {
            width: 1000,
            maxHeight: 900
            }
        }}>
      <DialogTitle>User Selection</DialogTitle>
      <DialogContent dividers style={{height:'900px'}}>
        <Box
                component="form"
                sx={{
                '& .MuiTextField-root': { m: 1, width: '1000' },
                }}
                noValidate
                autoComplete="off"
            >
            <div  style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <TextField
                label="Search"
                variant="outlined"
                fullWidth
                value={search}
                required={true}
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={handleEnterInUrl}
                />
                <Button variant="contained" onClick={loadData}>Search</Button>
            </div>
            <Box sx={{ width: '100%' }}>
                    { loading ? 
                    <LinearProgress key='waitingStatus'  />
                    : null}
            </Box>
        </Box>
        <DataGrid
            rows={tableData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            autoHeight={true}
            experimentalFeatures={{ newEditingApi: true }}
        />
      </DialogContent>
      <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
    </Dialog>
  );
};

interface ItemSelectionButtonProps {
  onItemSelected: (selectedItem: string) => void;
}

const UserSelectDialog: React.FC<ItemSelectionButtonProps> = ({ onItemSelected }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleItemSelected = (selectedItem: string) => {
    onItemSelected(selectedItem ? selectedItem : "");
  };

  return (
    <div>
      <Button onClick={handleOpenDialog}>Find user</Button>
      <ItemSelectionDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSelect={handleItemSelected}
      />
    </div>
  );
};

 export default UserSelectDialog;
