import React, { useState } from 'react';

interface ConfigItem {
  systemName: string;
  name: string;
  value: string;
}

interface ConfigurableListProps {
  config: ConfigItem[];
}


const ConfigurableList: React.FC<ConfigurableListProps> = ({ config }) => {
    const [editableConfig, setEditableConfig] = useState<ConfigItem[]>(config);
  
    const handleInputChange = (index: number, value: string) => {
      const newConfig = [...editableConfig];
      newConfig[index].value = value;
      setEditableConfig(newConfig);
    };
  
  
    return (
      <div>
        {editableConfig.map((item, index) => (
          <div key={item.systemName} style={{ marginBottom: '10px' }}>
                <label style={{ marginRight: '10px', width: "300px", display: 'inline-block' }}>{item.name}</label>
                <input
                type="text"
                value={item.value}
                style={{width: "550px"}}
                onChange={(e) => handleInputChange(index, e.target.value)}
                />
          </div>
        ))}
      </div>
    );
  };
  
  export default ConfigurableList;
  