import React from 'react'
import Box from '@mui/material/Box';
import { useState,useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    { field: 'consentizerId', headerName: 'consentizerId', width: 350 },
    { field: 'email', headerName: 'email', width: 320 },
    { field: 'lastLoginDate', headerName: 'Last login', width: 160,   type: 'dateTime',  valueFormatter: params => moment(params?.value).format("DD.MM.YYYY HH:mm:ss") },
    { field: 'registeredDate', headerName: 'Registered', width: 160,   type: 'dateTime',  valueFormatter: params => moment(params?.value).format("DD.MM.YYYY HH:mm:ss") },
    { field: 'privacyPreference', headerName: 'Preference', width: 190 },
  ];

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {

      const loadData = async() => {

          setLoading(true)

          const token = await getAccessTokenSilently();

          const response = await fetch(process.env.REACT_APP_REPORTS_URL +"?reportId=rep003&days=30", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response.status) {
              const items = await response.json();
              if (items != null)
                setTableData(items.rep003);
              console.log("data loaded.")
            }
          setLoading(false)
      };


    loadData();
    }, [getAccessTokenSilently])

  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
        rowCount={tableData.length}
        loading={loading}
      />

    </Box>
  );
}