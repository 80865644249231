import React, { FC, ReactNode } from "react";
import { Box, CssBaseline } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import Menu from '../Menu';

const drawerWidth = 240;

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <Menu />
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar />
            {children}
        </Box>
      </Box>
    </>
  /*
    <>
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          minHeight: "100vh",
          maxWidth: "100vw",
          flexGrow: 1,
        }}
      >
        <Navbar />
        {children}
        <Footer />
      </Box>
    </>*/
  );
};

export default Layout;