import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState } from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {saveAs} from "file-saver";
import UserSelectDialog from '../components/UserSelectDialog';


export default function DataGridDemo() {

    const { getAccessTokenSilently } = useAuth0();
    const [consentizerId, setConsentizerId] = useState('');
    const [encConsentizerId, setEncConsentizerId] = useState('');
    const [savedMessage, setSavedMessage] = React.useState(false);

    const changeConsentizerId = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setConsentizerId(event.target.value)
    }

    const changeEncConsentizerId = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setEncConsentizerId(event.target.value)
    }



    const buttonHandler = async() => {

        console.log("button clicked"+consentizerId);

        if (consentizerId === '' && encConsentizerId === '') return;

        const token = await getAccessTokenSilently();

        const response = await fetch(process.env.REACT_APP_USERDELETE_URL +"?consentizerId="+consentizerId+"&encConsentizerId="+encConsentizerId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        if (response.status) {
            console.log("data loaded.")
          }

      setSavedMessage(true);

    };

    const buttonHandlerLoad = async() => {

      console.log("button clicked"+consentizerId);

      if (consentizerId === '' && encConsentizerId === '') return;

      const token = await getAccessTokenSilently();

      const response = await fetch(process.env.REACT_APP_GETUSERDATA_URL +"?consentizerId="+consentizerId+"&encConsentizerId="+encConsentizerId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status) {
          
          console.log("data deleted.")
          console.log(response.blob)
          var data = await response.blob()
          console.log(data)
          saveAs(data, `userdata.json`)

        }

    setSavedMessage(true);

  };
    
  const userSelected = (selectedItem: string) => {
    // Handle the selected item
    if (selectedItem !== '')
      setConsentizerId(selectedItem);
  };

  const savedMessageClose = () => {
    setSavedMessage(false);
  }

   
  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
        <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <TextField label="ConsentizerId" id="txtConsentizerId" required value={consentizerId} onChange={changeConsentizerId} inputProps={{ style: { width: "600px" }}}
                   />
                <UserSelectDialog
                  onItemSelected={userSelected}
                />
                <TextField label="encConsentizerId" id="txtEncConsentizerId" required value={encConsentizerId} onChange={changeEncConsentizerId} inputProps={{ style: { width: "600px" }}}
                   />
                <Button variant="contained" onClick={buttonHandlerLoad}>Load</Button>
                <Button variant="contained" onClick={buttonHandler}>Delete</Button>

            </div> 
        </Box>
        <Snackbar
        open={savedMessage}
        autoHideDuration={3000}
        onClose={savedMessageClose}
      >
          <Alert severity="success" sx={{ width: '100%' }}>
        Data deleted.
      </Alert>
      </Snackbar>
    </Box>
  );
}