import React from 'react'
import Box from '@mui/material/Box';
import { useState,useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import moment from "moment";
import TextField from '@mui/material/TextField';
import {Link} from 'react-router-dom';

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'url', headerName: 'URL', width: 300,
    renderCell: (params) => (
      <><a href={`https://${params.value}`} target='_blank' rel="noreferrer">{params.value}</a><Link to={`/actions/${params.value}`}>
        <Button>Edit Actions</Button>
      </Link></>
    )
    },
    { field: 'noneScreenshotDate', headerName: 'none - time of screenshot', width: 220,   type: 'dateTime',  valueFormatter: params => moment(params?.value).format("DD.MM.YYYY HH:mm:ss") },

    {
      field: 'noneScreenshotUrl',
      headerName: 'noneScreenshotUrl',
      width: 400,
      renderCell: (params) => <img src={'https://sawaulterfunctions.blob.core.windows.net/webcrawler/low_'+params.value} alt="img" onClick={() => handleOpen(params.row)} />, // renderCell will render the component
    }
  ];

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [url, setUrl] = useState('');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = useState(false);
    const [screnshotUrl, setScreenshotUrl] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const loadData = async(url:string, search:string) => {

      setLoading(true)

      const token = await getAccessTokenSilently();

      const response = await fetch(process.env.REACT_APP_REPORTS_URL +"?reportId=rep004&url="+url+"&search="+search, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.status) {
          const events = await response.json();
          if (events != null)
            setTableData(events.rep004);
          console.log("data loaded.")
        }
      setLoading(false)
    };

    useEffect(() => {

      loadData(url,search);
      // eslint-disable-next-line
    }, [])

    // OPEN DIALOG
    const handleOpen = (props:any) => {
      setScreenshotUrl(props.noneScreenshotUrl);
      setOpen(true);
    };
    
    //search button
    const buttonHandler = async() => {

      console.log("search clicked:"+search);

      loadData(url,search);

    };

    //search text change
    const changeSearch = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setSearch(event.target.value)
    }
    
    //url text change
    const changeUrl = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setUrl(event.target.value)
    }

    //CLOSE DIALOG
    const handleClose = () => {
      setOpen(false);
    };

    // URL TEXTBOX KEY PRESS HANDLER = ENTER
    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevent form submission
        loadData(url, search);
      }
    };

  return (
    <Box sx={{ height: `auto`, width: '100%' }}>

        <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <TextField label="url" id="txtConsentizerId" required value={url} onChange={changeUrl} onKeyDown={handleEnter} inputProps={{ style: { width: "300px" }}}
                   />
                <TextField label="keywords" id="txtConsentizerId" required value={search} onChange={changeSearch} onKeyDown={handleEnter} inputProps={{ style: { width: "600px" }}}
                   />
                <Button variant="contained" onClick={buttonHandler}>Search</Button>&nbsp;&nbsp;

            </div> 
      </Box>

      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
        rowCount={tableData.length}
        rowHeight={300}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'noneScreenshotDate', sort: 'desc' }],
          },
        }}
      />
    <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            width: "100%",
            maxWidth: "100%!important",
          },
        }}
      >
      <DialogTitle>Screenshot</DialogTitle>

        <DialogContent dividers>
        <img src={'https://sawaulterfunctions.blob.core.windows.net/webcrawler/'+screnshotUrl} alt="img" /> 
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
        </Dialog>
    </Box>
  );
}