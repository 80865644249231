import React from 'react'
import Box from '@mui/material/Box';
import { useState,useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    { field: 'url', headerName: 'URL', width: 400,
    renderCell: (params) => (
      <><a href={`https://${params.value}`} target='_blank' rel="noreferrer">{params.value}</a><Link to={`/actions/${params.value}`}>
        <Button>Edit Actions</Button>
      </Link></>
    )
    },
    { field: 'usageCount', headerName: 'Usage count', width: 90 },
  ];

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = React.useState(false)

    useEffect(() => {

      const loadData = async() => {

          setLoading(true)

          const token = await getAccessTokenSilently();

          const response = await fetch(process.env.REACT_APP_REPORTS_URL +"?reportId=rep001", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          
          if (response.status) {
              const events = await response.json();
              if (events != null)
                setTableData(events.rep001);
              console.log("data loaded.")
              setLoading(false)
            }
      };


    loadData();
    }, [getAccessTokenSilently])

  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
        rowCount={tableData.length}
        loading={loading}
      />

    </Box>
  );
}