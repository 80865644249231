import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState,useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import {saveAs} from "file-saver";
import {Link} from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import moment from "moment";

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    { field: 'url', headerName: 'URL', width: 300,
    renderCell: (params) => (
      <><a href={`https://${params.value}`} target='_blank' rel="noreferrer">{params.value}</a><Link to={`/actions/${params.value}`}>
        <Button>Edit Actions</Button>
      </Link></>
    )
    },
    {
      field: 'applyOnSubdomains', headerName: 'Apply on subdomains', width: 100, renderCell: (params) => (
        <>{params.row.applyOnSubdomains ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'noneHide', headerName: 'None Hide', width: 90, renderCell: (params) => (
        <>{params.row.noneHide ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'noneClick', headerName: 'None Click', width: 90, renderCell: (params) => (
        <>{params.row.noneClick ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'noneCookieDelete', headerName: 'None Cookie Delete', width: 90, renderCell: (params) => (
        <>{params.row.noneCookieDelete ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'noneRemoveCSS', headerName: 'None Remove CSS', width: 90, renderCell: (params) => (
        <>{params.row.noneRemoveCSS ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'noneSetCSS', headerName: 'None Set CSS', width: 90, renderCell: (params) => (
        <>{params.row.noneSetCSS ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'noneNonAutoDelete', headerName: 'None Non Auto Delete', width: 90, renderCell: (params) => (
        <>{params.row.noneNonAutoDelete ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'balancedHide', headerName: 'Bal. Hide', width: 90, renderCell: (params) => (
        <>{params.row.balancedHide ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'balancedClick', headerName: 'Bal. Click', width: 90, renderCell: (params) => (
        <>{params.row.balancedClick ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'balancedCookieDelete', headerName: 'Bal. Cookie Delete', width: 90, renderCell: (params) => (
        <>{params.row.balancedCookieDelete ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'balancedRemoveCSS', headerName: 'Bal. Remove CSS', width: 90, renderCell: (params) => (
        <>{params.row.balancedRemoveCSS ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'balancedSetCSS', headerName: 'Bal. Set CSS', width: 90, renderCell: (params) => (
        <>{params.row.balancedSetCSS ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    {
      field: 'balancedNonAutoDelete', headerName: 'Bal. Non Auto Delete', width: 90, renderCell: (params) => (
        <>{params.row.balancedNonAutoDelete ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'changed', headerName: 'Changed', width: 200,   type: 'dateTime',  valueFormatter: params => params?.value ? moment(params?.value).format("DD.MM.YYYY HH:mm:ss"):"" },

  ];

    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [url, setUrl] = useState('');
    const [loading, setLoading] = React.useState(false)


    const changeUrl = (event: { target: { value: React.SetStateAction<string>; }; }) => {
      setUrl(event.target.value)
    }

  //LOAD
  const loadData = async(url:string) => {

    setLoading(true)

    const token = await getAccessTokenSilently();

    const response = await fetch(process.env.REACT_APP_REPORTS_URL +"?reportId=rep002&url="+url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response.status) {
        const events = await response.json();
        if (events != null)
          setTableData(events.rep002);
        console.log("data loaded.")
        setLoading(false)
    }
  };

  //EXPORT
  const exportData = async(url:string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(process.env.REACT_APP_REPORTS_URL +"?reportId=rep002&url="+url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    if (response.status) {
        var data = await response.blob()

        saveAs(data, `rep002.json`)

        console.log("data exported.")
      }
  };

  const buttonHandler = async() => {
    console.log("button clicked"+url);
    loadData(url);
  };

  const exportHandler = async() => {

    console.log("export button clicked"+url);
    exportData(url);
  };  

  // URL TEXTBOX KEY PRESS HANDLER = ENTER
  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // prevent form submission
      loadData(url);
    }
  };

  useEffect(() => { 
    loadData('');
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ height: `auto`, width: '100%' }}>

        <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <TextField label="url" id="txtConsentizerId" required value={url} onChange={changeUrl} onKeyDown={handleEnter} inputProps={{ style: { width: "600px" }}}
                   />
                <Button variant="contained" onClick={buttonHandler}>Search</Button>&nbsp;&nbsp;
                <Button variant="contained" onClick={exportHandler}>Export</Button>

            </div> 
        </Box>


      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13,20,50,100]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
        rowCount={tableData.length}
        initialState={{
          sorting: {
            sortModel: [{ field: 'url', sort: 'asc' }],
          },
        }}
        loading={loading}
      />

    </Box>
  );
}