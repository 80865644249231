// pages
import Codebooks from "./pages/Codebooks";
import Agreements from "./pages/Agreements";
import Partners from "./pages/Partners";
import Acceptances from "./pages/Acceptances";
import Companies from "./pages/Companies";
import WebDetail from "./pages/WebDetail";
import Cookies from "./pages/Cookies";
import Events from "./pages/Events";
import Actions from "./pages/Actions";
import UserDelete from "./pages/Delete";
import Login from "./pages/Login";
import Rep001 from "./pages/Rep001";
import Rep002 from "./pages/Rep002";
import Rep003 from "./pages/Rep003";
import Rep004 from "./pages/Rep004";


// other
import {FC} from "react";

// interface
interface Route {
    key: string,
    title: string,
    path: string,
    pathWithoutParams: string,
    enabled: boolean,
    component: FC<{}>,
    icon: string
}

export const routes: Array<Route> = [
    {
        key: 'login',
        title: 'Login',
        path: '/',
        pathWithoutParams: '/',
        enabled: true,
        component: Login,
        icon: 'Input'
    },
    {
        key: 'home-route',
        title: 'Codebooks',
        path: '/codebooks',
        pathWithoutParams: '/codebooks',
        enabled: true,
        component: Codebooks,
        icon: 'ListAlt'
    },
    {
        key: 'agreements-route',
        title: 'Agreements',
        path: '/agreements',
        pathWithoutParams: '/agreements',
        enabled: true,
        component: Agreements,
        icon: 'Balance'
    },
    {
        key: 'partners-route',
        title: 'Partners',
        path: '/partners',
        pathWithoutParams: '/partners',
        enabled: true,
        component: Partners,
        icon: 'Handshake'
    },
    {
        key: 'acceptances-route',
        title: 'Acceptances',
        path: '/acceptances',
        pathWithoutParams: '/acceptances',
        enabled: true,
        component: Acceptances,
        icon: 'HowToReg'
    },
    {
        key: 'companies-route',
        title: 'Companies',
        path: '/companies',
        pathWithoutParams: '/companies',
        enabled: true,
        component: Companies,
        icon: 'Business'
    },
    {
        key: 'webdetails-route',
        title: 'Web details',
        path: '/webdetails',
        pathWithoutParams: '/webdetails',
        enabled: true,
        component: WebDetail,
        icon: 'Language'
    },
    {
        key: 'cookies-route',
        title: 'Cookies',
        path: '/cookies',
        pathWithoutParams: '/cookies',
        enabled: true,
        component: Cookies,
        icon: 'Cookie'
    },
    {
        key: 'events-route',
        title: 'Events',
        path: '/events',
        pathWithoutParams: '/events',
        enabled: true,
        component: Events,
        icon: 'Event'
    },
    {
        key: 'actions-route',
        title: 'Actions',
        path: '/actions/:url?',
        pathWithoutParams: '/actions',
        enabled: true,
        component: Actions,
        icon: 'ReceiptLong'
    },
    {
        key: 'userdelete-route',
        title: 'User Delete',
        path: '/userdelete',
        pathWithoutParams: '/userdelete',
        enabled: true,
        component: UserDelete,
        icon: 'PersonRemove'
    },
    {
        key: 'rep001-route',
        title: 'Report: URL bez akcí',
        path: '/rep001',
        pathWithoutParams: '/rep001',
        enabled: true,
        component: Rep001,
        icon: 'Assessment'
    },
    {
        key: 'rep002-route',
        title: 'Report: Přehled akcí',
        path: '/rep002',
        pathWithoutParams: '/rep002',
        enabled: true,
        component: Rep002,
        icon: 'Assessment'
    },
    {
        key: 'rep003-route',
        title: 'Report: Aktivní uživatelé',
        path: '/rep003',
        pathWithoutParams: '/rep003',
        enabled: true,
        component: Rep003,
        icon: 'Assessment'
    },
    {
        key: 'rep004-route',
        title: 'Report: Screenshoty webů',
        path: '/rep004',
        pathWithoutParams: '/rep004',
        enabled: true,
        component: Rep004,
        icon: 'Assessment'
    },
]