import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useAuth0 } from "@auth0/auth0-react";
import Dialog  from '@mui/material/Dialog';
import { IconButton } from '@mui/material';
import DisplaySettingsIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import moment from "moment";
import ConfirmDialog from '../components/ConfirmDialog';
import 'react-tagsinput/react-tagsinput.css'
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CompanySelectDialog from '../components/CompanySelectDialog';
import {Link} from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Multiselect from "../components/Multiselect"

export default function DataGridDemo() {

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "Actions",
      width:50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleOpen(params.row)}>
            <DisplaySettingsIcon />
          </IconButton>
        );
      }
    },
    { field: 'id', headerName: 'id', width: 140},
    { field: 'url', headerName: 'url', width: 300},
    {
      field: 'publicWeb', headerName: 'Public web', width: 70, renderCell: (params) => (
        <>{params.row.publicWeb ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },    {
      field: 'processed', headerName: 'Processed', width: 70, renderCell: (params) => (
        <>{params.row.processed ? (
            <CheckCircleIcon style={{ color: 'green' }} />
            ) : (
            <RemoveCircleIcon style={{ color: 'red' }} />
          )}</>),
    },
    { field: 'title', headerName: 'title', width: 300},
    { field: 'changedUsername', headerName: 'Changed by', width: 320 },
    { field: 'changed', headerName: 'Changed', width: 220,   type: 'dateTime',  valueFormatter: params => params?.value ? moment(params?.value).format("DD.MM.YYYY HH:mm:ss"):"" },

  ];




    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);


    const { getAccessTokenSilently } = useAuth0();
    const [tableData, setTableData] = useState([]);
    const [codebookWebTags, setCodebookWebTags] = useState<{value:string, label:string}[]>([]);

    const [url, setUrl] = useState('');
    const [processedFilter, setProcessedFilter] = useState('all');

    const [item, setItem] = useState(Object);
    const [selectedWebTags, setSelectedWebTags] = useState<string[]>([]);

    const [tabValue, setTabValue] = React.useState('1');
    const [notFoundCompany, setNotFoundCompany] = React.useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    //close of not found company notification
    const notFoundCompanyClose = () => {
      setNotFoundCompany(false);
    }


    //HANDLE TAB CHANGE ON PARTNER DETAIL DIALOG
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setTabValue(newValue);
    };

    //PRESS ENTER IN SEARCH TEXT BOX
    const handleEnterInUrl = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // prevent form submission
        loadData();
      }
    };


    //LOAD DATA TO PARTNERS LIST
    // eslint-disable-next-line
    const loadData = async() => {

      console.log("button clicked ");
      setLoading(true);

      const token = await getAccessTokenSilently();

      const response = await fetch(process.env.REACT_APP_WEBDETAIL_URL +"?url="+url+"&processedFilter="+processedFilter, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status) {
          const companies = await response.json();
          if (companies != null)
            setTableData(companies);
          console.log("data loaded.")
        }
        setLoading(false);
  };

    //LOAD CODEBOOKS = PURPOSES AND CATEGORES
  const loadCodebooks = async() => {

    const token = await getAccessTokenSilently();

    const response = await fetch(process.env.REACT_APP_CODEBOOK_URL +"?codebookId=C007", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status) {
        const codebook = await response.json();
        if (codebook != null)
        {
          var array:any[] = [];
          codebook.items.filter((item:any) => item.active===true).forEach((item:any) => {
            array.push({value:item.code, label:item.name})
          });
          setCodebookWebTags(array);
        }
        console.log("data loaded.")
      }
};

    //AUTO LOAD AFTER DIALOG INITIALIZATION
    useEffect(() => {
      loadData();
      loadCodebooks();
    // eslint-disable-next-line
    }, [])

        // OPEN COMPANY DIALOG
        const handleOpen = async(props:any) => {
          if (props.id === 'new') {
            setItem({ url:'', company:'', title:'', description:'', linkCookies:'', linkPrivacy:'', linkCookiesMetadata:'', processed:false, publicWeb:false, otherRegNums:[]});
            setTabValue('1');
            setSelectedWebTags([]);
            setOpen(true);
            return
          }
          console.log("company open "+ props.id);
          setLoading(true);

          const token = await getAccessTokenSilently();

          const response = await fetch(process.env.REACT_APP_WEBDETAIL_URL +"/"+props.url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (response.status) {
              const webDetail = await response.json();
              if (webDetail != null)
                setItem(webDetail);
              console.log("data loaded.")
            }
            setLoading(false);
          setOpen(true);
        };

        //CLOSE COMPANY DIALOG
        const handleClose = () => {
          setOpen(false);
        };


        //SAVE COMPANY DIALOG
        const handleSave = async() => {

          const itemToSave = item;

          console.log(itemToSave);

          const token = await getAccessTokenSilently();

          const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ` + token },
            body: JSON.stringify(itemToSave)
            };
          await fetch(process.env.REACT_APP_WEBDETAIL_URL, requestOptions);

          loadData();
          setOpen(false);
        }


        //DELETE COOKIE
        const handleDelete = async() => {


          const token = await getAccessTokenSilently();

          const requestOptions = {
            method: 'DELETE',
            headers: {
                        'Authorization': `Bearer ` + token }
            };
          await fetch(process.env.REACT_APP_COOKIE_URL + '?id=' + item.id, requestOptions);

          loadData();
          setOpen(false);
        }

        // ADD COMPANY
        const addCompany = async(company:any) => {
            console.log('Adding company '+ company)
            setItem((prevState:any) => ({
              ...prevState,
                company: company.name,
                companyId: company.id
            }))
        }

        // REMOVE COMPANY
        const removeCompany = async(company:any) => {
            console.log('Removing company '+ company)
            setItem((prevState:any) => ({
              ...prevState,
                company: undefined,
                companyId: undefined
            }))
        }

  return (
    <Box sx={{ height: `auto`, width: '100%' }}>
      <Box
            component="form"
            sx={{
            '& .MuiTextField-root': { m: 1, width: '1000' },
            }}
            noValidate
            autoComplete="off"
          >
                <div  style={{
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <Button variant="contained" onClick={() => handleOpen({id:'new', otherRegNums:[]})} sx={{pr:2}}>New web detail</Button>
                <TextField label="Search in url" id="txtSearch" value={url} onChange={(event:any) => setUrl(event.target.value)} onKeyDown={handleEnterInUrl} inputProps={{ style: { width: "300px" }}} />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">Filter processed</InputLabel>
                  <Select
                    id="demo-simple-select"
                    label="Filter processed"
                    value={processedFilter}
                    onChange={(event: SelectChangeEvent) => { setProcessedFilter(event.target.value as string); }}
                    style={{ minWidth: "170px",padding:"5px" }}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'notProcessed'}>Not processed</MenuItem>
                    <MenuItem value={'processed'}>Processed</MenuItem>
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={loadData}>Search</Button>
            </div>
            <Box sx={{ width: '100%' }}>
                { loading ?
                  <LinearProgress key='waitingStatus'  />
                : null}
            </Box>
      </Box>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        autoHeight={true}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        PaperProps={{
          sx: {
            width: 1000,
            maxHeight: 900
          }
        }}
      >
      <DialogTitle sx={{height:90}}>Web detail {item.url}</DialogTitle>
        <DialogContent dividers style={{height:'900px'}}>
        <Chip
          label={'Created by: ' +item.createdUsername + ' on: ' + moment(item.created).format("DD.MM.YYYY HH:mm:ss")}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10
          }}
        ></Chip>
        <Chip
          label={'Changed by:: ' +item.changedUsername + ' on: ' + moment(item.changed).format("DD.MM.YYYY HH:mm:ss")}
          sx={{
            position: 'absolute',
            right: 8,
            top: 50
          }}></Chip>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Company" value="1" />
              <Tab label="Screenshots" value="2" disabled/>
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={2} columns={2}>
              <Grid item xs={1}>
                <TextField
                        sx={{ m: 1}}
                        label="url"
                        placeholder="url"
                        value={item.url}
                        fullWidth
                        onChange={(event:any) => setItem((prevState:any) => ({
                          ...prevState,
                            url: event.target.value,
                        }))}
                        size="small"
                    />
              </Grid>
              <Grid item xs={1}>
                <TextField
                          sx={{ m: 1}}
                          label="Company"
                          placeholder="Company"
                          value={item.company}
                          contentEditable={false}
                          fullWidth
                          size="small"
                      />
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <CompanySelectDialog onItemSelected={addCompany}/>
                    <Button onClick={removeCompany}>Remove company</Button>
                  </div>
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel control={<Checkbox checked={item.processed}
                                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => setItem((prevState:any) => ({
                                                      ...prevState,
                                                      processed: event.target.checked,
                                                  }))} />} label="Processed" sx={{pl:1,height:60}} />
              </Grid>
              <Grid item xs={1}>
                <FormControlLabel control={<Checkbox checked={item.publicWeb}
                                          onChange={(event:React.ChangeEvent<HTMLInputElement>) => setItem((prevState:any) => ({
                                                        ...prevState,
                                                        publicWeb: event.target.checked,
                                                    }))} />} label="Public web" sx={{pl:1,height:60}} />
              </Grid>
            </Grid>
            <TextField
                      sx={{ m: 1}}
                      label="Title"
                      placeholder="Title"
                      value={item.title}
                      fullWidth
                      onChange={(event:any) => setItem((prevState:any) => ({
                                                    ...prevState,
                                                      title: event.target.value,
                                                  }))}
                      size="small"

                  />
            <TextField
                      sx={{ m: 1}}
                      label="Description"
                      placeholder="Description"
                      value={item.description}
                      fullWidth
                      rows={5}
                      onChange={(event:any) => setItem((prevState:any) => ({
                                                    ...prevState,
                                                    description: event.target.value,
                                                  }))}
                      size="small"

                  />
            <TextField
                      sx={{ m: 1}}
                      label="Cookie link"
                      placeholder="Cookie link"
                      value={item.linkCookies}
                      fullWidth
                      rows={5}
                      onChange={(event:any) => setItem((prevState:any) => ({
                                                    ...prevState,
                                                    linkCookies: event.target.value,
                                                  }))}
                      size="small"

                  />
            <TextField
                      sx={{ m: 1}}
                      label="Privacy link"
                      placeholder="Privacy link"
                      value={item.linkPrivacy}
                      fullWidth
                      rows={5}
                      onChange={(event:any) => setItem((prevState:any) => ({
                                                    ...prevState,
                                                    linkPrivacy: event.target.value,
                                                  }))}
                      size="small"

                  />
            <TextField
                      sx={{ m: 1}}
                      label="Cookies metadata link"
                      placeholder="Cookies metadata link"
                      value={item.linkCookiesMetadata}
                      fullWidth
                      rows={5}
                      onChange={(event:any) => setItem((prevState:any) => ({
                                                    ...prevState,
                                                    linkCookiesMetadata: event.target.value,
                                                  }))}
                      size="small"

                  />
            <Multiselect label="Web tags" options={codebookWebTags} selected={selectedWebTags} onChange={(selectedItems: string[]) => { setSelectedWebTags(selectedItems); }}></Multiselect>

          </TabPanel>

          <TabPanel value="2">



          </TabPanel>
        </TabContext>

        </DialogContent>

        <DialogActions>
          <Link to={`/actions/${item.url}`} style={{padding: '30px'}}>
            <Button >Edit Actions</Button>
          </Link>
          <Button variant="contained" onClick={handleSave}>Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        open={notFoundCompany}
        autoHideDuration={3000}
        onClose={notFoundCompanyClose}
      >
          <Alert severity="error" sx={{ width: '100%' }}>
            Can't find company with specified regnum.
          </Alert>
      </Snackbar>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        title="Confirmation"
        message="Are you sure you want to delete this item?"
        onConfirm={handleDelete}
        onCancel={() => { setIsConfirmDialogOpen(false)}}
      />

    </Box>
  );
}
