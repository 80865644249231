import Box from '@mui/material/Box';
import { useAuth0 } from "@auth0/auth0-react";


export default function Login() {

    const {
        isAuthenticated,
      } = useAuth0();


    return (
        <Box sx={{ height: `auto`, width: '100%' }}>
            { !isAuthenticated ? 
            "Login into admin by click on button in top menu."
                : "Welcome to Waulter Admin, choose action in left menu."}
        </Box>
      );
    }

