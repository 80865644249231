// MultiSelectComponent.tsx
import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Chip } from '@mui/material';
import { Stack, OutlinedInput } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps {
  label: string;
  options: Option[];
  selected: string[];
  onChange: (selectedItems: string[]) => void;
}

const MultiSelectComponent: React.FC<MultiSelectProps> = ({ label, options, selected, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(selected);

  return (
    <FormControl sx={{ m: 1, width: 900 }}>
    <InputLabel>{label}</InputLabel>
    <Select
      multiple
      value={selectedOptions}
      onChange={(e:any) => { setSelectedOptions(e.target.value); onChange(e.target.value)} }
      input={<OutlinedInput label="Multiple Select" />}
      renderValue={(selected) => (
        <Stack gap={1} direction="row" flexWrap="wrap">
          {selected.map((value) => {
             const option = options.find((item) => item.value === value);

  // Check if the option is found before rendering the Chip
  if (option) {
    return (
            <Chip
              key={value}
              label={options.filter((item) => item.value === value)[0].label }
              onDelete={() =>
                {
                  var selected = selectedOptions.filter((item) => item !== value)
                  setSelectedOptions(selected)
                  onChange(selected)
                }
              }
              deleteIcon={
                <CancelIcon
                  onMouseDown={(event) => event.stopPropagation()}
                />
              }
            />
            );
          }
        
          // Handle the case where the option is not found (optional)
          console.warn(`Option with value '${value}' not found in multiselect options, removing.`);
          setSelectedOptions(selectedOptions.filter(item => item !== value))
          onChange(selectedOptions.filter(item => item !== value))
          return null;
        })}
        </Stack>
      )}
    >
      {options.map((item:any) => (
        <MenuItem
          key={item.value}
          value={item.value}
          sx={{ justifyContent: "space-between" }}
        >
          {item.label}
          {selectedOptions.includes(item.value) ? <CheckIcon color="info" /> : null}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  );
};

export default MultiSelectComponent;
